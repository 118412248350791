import {
    Dialog,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
//import closeIcon from './assets/close-icon.svg'

import { CgClose } from 'react-icons/cg'; 
const closeIcon = <CgClose />;

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: "700px",
        height: "550px",
        padding: "0px",
        margin: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    close: {
        position: "absolute",
        top: "0px",
        right: "0px",
        width: "48px",
        height: "42px",
        paddingLeft: "16px",
        paddingTop: "12px",
        color: theme.palette.background.text,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
            cursor: "pointer",
        }
    },
  }));

function ImgModal(props) {
    const classes = useStyles()

    return (
        <Dialog 
            open={props.open}
            classes={{paper: classes.dialog}}
            fullWidth={true}
        >
            <span 
                className={classes.close}
                onClick={()=>{props.callback()}}
            >
                {closeIcon}
            </span>
            <img 
                src={props.image && URL.createObjectURL(props.image)} 
                alt=""
                className={classes.image}
            />
        </Dialog>
    )
}

export default ImgModal