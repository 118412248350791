import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { 
    getUserInfo, 
    updateUserInfo, 
    toggleNotifications 
} from '../actions'
import { 
    Typography, 
    Button, 
    TextField, 
    Paper, 
    Divider, 
    Switch, 
    Tooltip 
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check'
import { isFlag } from "../commons"
import SubDrawer from "./subdrawer"
import Avatar from "./avatar"

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: "240px", // mauro it was 360px
        padding: "0px 40px 40px 40px",
        height: "100%"
    },
    row: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    cardRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    title: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontSize: theme.typography.listTitle.fontSize,
        fontWeight: theme.typography.listTitle.fontWeight,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis
    },
    outlinedBtn: {
        width: "100px",
        height: "35px",
        borderColor: theme.palette.borderColor.onSurface
    },
    primaryBtn: {
        width: "120px",
        height: "36px"
    },
    form: {
        marginTop: "20px",
    },
    textField: {
        width: "350px",
        height: "40px",
        marginBottom: "40px",
        marginRight: "10px"
    },
    fieldLabel: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    permissionsCard: {
        width: "360px",
        padding: "25px"
    },
    permissionsTextDisabled: {
        color: theme.palette.onSurface.disabled
    },
    card: {
        background: theme.palette.background.row,
        padding: "24px",
        width: "350px",
        height: "158px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "10px",
        marginBottom: "20px"
    },
    boldTitle: {
        fontFamily: theme.typography.boldTitle.fontFamily,
        fontSize: theme.typography.boldTitle.fontSize,
        fontWeight: theme.typography.boldTitle.fontWeight,
        lineHeight: theme.typography.boldTitle.lineHeight,
        letterSpacing: theme.typography.boldTitle.letterSpacing,
    },
    infoContainer: {
        width: "60%"
    },
    switchRows: {
        height: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    thumbOff: {
        color: "black",
    },
    tooltip: {
        borderRadius: "4px"
    }
  }));

function Settings(props) {
    const Email = 2
    const Notification = 1
    const classes = useStyles()
    const listTitle = "Settings"
    const listItems = ["User Profile", "Subscription", "Appearance"]
    const [isChecked, setIsChecked] = useState({
        email: isFlag(props.userInfo?.notification, Email),
        notification: isFlag(props.userInfo?.notification, Notification)
    });
    const [isDisabled, setIsDisabled] = useState(true)
    const [fields, setFields] = useState({
        name: props.userInfo?.name || "",
        lastName: props.userInfo?.lastName || "",
        email: props.userInfo?.email ||"",
        password: props.userInfo?.password || "",
        institution: props.userInfo?.institution || "",
        researcher: props.userInfo?.researcher || ""
    })

    useEffect(()=>{
        props.getUserInfo({userId: props.user.id})
    }, [props.user, props.updatedUserInfo])

    useEffect(()=>{
        setFields({
            name: props.userInfo?.name || "",
            lastName: props.userInfo?.lastName || "",
            email: props.userInfo?.email ||"",
            password: props.userInfo?.password || "",
            institution: props.userInfo?.institution || "",
            researcher: props.userInfo?.researcher || ""
        });
        setIsChecked({
            email: isFlag(props.userInfo?.notification, Email),
            notification: isFlag(props.userInfo?.notification, Notification)
        });
    }, [props.userInfo, props.updatedUserInfo])

    const handleChange = (event) => {
        switch (event.target.name) {
            case "email": 
                props.toggleNotifications({userId: props.user.id, flag: Email});
                break;
            case "notification":
                props.toggleNotifications({userId: props.user.id, flag: Notification});
                break;
            case "tooltips":
                props.handleTooltip();
                break;
        }
    };

    const handleInputChange = (e) => {
        setFields({...fields, [e.target.name]: e.target.value})
    }

    const handleClick = () => {
        if (isDisabled === false) {
            props.updateUserInfo({
                userId: props.user.id,
                name: fields.name,
                lastName: fields.lastName,
                institution: fields.institution,
                researcher: fields.researcher
            })
        }
        setIsDisabled(!isDisabled)
    }


    return (
        <>
        <SubDrawer 
            listTitle={listTitle}
            listItems={listItems} 
            menuOpen={props.menuOpen}
        />
        <div className={classes.container}>
            <div className={classes.row}>
                <Typography className={classes.title}>
                    Account Info
                </Typography>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={()=>{handleClick()}}
                    className={classes.outlinedBtn}
                >
                    <Typography variant="button">
                        {isDisabled ? "edit" : "save"}
                    </Typography>
                </Button>
            </div>
            <div className={classes.infoContainer}>
            <Avatar />
            <div className={classes.form}>
                <div className={classes.row}>
                    <div>
                        <Typography 
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            First Name
                        </Typography>
                        <TextField 
                            variant="outlined" 
                            disabled={isDisabled} 
                            name="name"
                            value={fields.name || ""}
                            onChange={(e)=>handleInputChange(e)}
                            className={classes.textField}
                        />
                    </div>
                    <div>
                        <Typography 
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Last Name
                        </Typography>
                        <TextField 
                            variant="outlined" 
                            disabled={isDisabled} 
                            name="lastName"
                            value={fields.lastName || ""}
                            onChange={(e)=>handleInputChange(e)}
                            className={classes.textField}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div>
                        <Typography 
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Email
                        </Typography>
                        <TextField 
                            variant="outlined" 
                            disabled={true} 
                            name="email"
                            value={fields.email || ""}
                            className={classes.textField}
                        />
                    </div>
                    <div>
                        <Typography 
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Password
                        </Typography>
                        <TextField 
                            variant="outlined" 
                            disabled={true} 
                            name="password"
                            value="mama"
                            type="password"
                            className={classes.textField}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div>
                        <Typography 
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Institution
                        </Typography>
                        <TextField 
                            variant="outlined" 
                            disabled={isDisabled}
                            name="institution"
                            value={fields.institution || ""}
                            onChange={(e)=>handleInputChange(e)}
                            className={classes.textField}
                        />
                    </div>
                    <div>
                        <Typography 
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Role
                        </Typography>
                        <TextField 
                            variant="outlined" 
                            disabled={isDisabled} 
                            name="researcher"
                            value={fields.researcher || ""}
                            onChange={(e)=>handleInputChange(e)}
                            className={classes.textField}
                        />
                    </div>
                </div>
            </div>
            <div >
                <Typography
                    variant="subtitle2"
                    className={classes.fieldLabel}
                >
                    Permissions
                </Typography>
                <div className={classes.cardRow}>
                    <Paper elevation={0} className={classes.permissionsCard}>
                        <Typography variant="subtitle2">
                            <CheckIcon 
                                color="primary" 
                                fontSize="inherit"
                            /> 
                            Create new models
                        </Typography>
                        <Typography variant="subtitle2">
                            <CheckIcon 
                                color="primary" 
                                fontSize="inherit"
                            /> 
                            Create new phenotypes
                        </Typography>
                        <Typography variant="subtitle2">
                            <CheckIcon 
                                color="primary" 
                                fontSize="inherit"
                            /> 
                            Create new targets
                        </Typography>
                        <Typography 
                            variant="body2"
                            className={classes.permissionsTextDisabled}
                        >
                            • Upload models
                        </Typography>
                        <Typography 
                            variant="body2"
                            className={classes.permissionsTextDisabled}
                        >
                            • Upload phenotypes
                        </Typography>
                        <Typography 
                            variant="body2"
                            className={classes.permissionsTextDisabled}
                        >
                            • Upload targets
                        </Typography>
                    </Paper>
                    <Paper  
                        elevation={0}
                        className={classes.card}
                    >
                        <Typography className={classes.boldTitle}>
                            Do you need more power? 
                        </Typography>
                        <Typography variant="caption">
                            <p>Get additional resources and improve your workflow.</p><br />
                        </Typography>
                        <Button 
                            variant="contained" 
                            disableElevation 
                            color="primary"
                            className={classes.primaryBtn}
                            onClick={()=>{window.open("https://netabolics.ai/#contacts", "_blank")}}
                        >
                            <Typography variant="button">
                                Upgrade
                            </Typography>
                        </Button>
                    </Paper>
                </div>
                <Divider />
                <div className={classes.switchRows}>
                    <Typography
                        variant="subtitle2"
                        className={classes.fieldLabel}
                    >
                        Notifications
                    </Typography>
                    <div className={classes.row}>
                        <Typography
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Email
                        </Typography>
                            <Tooltip 
                                arrow 
                                title="When notifications are turned off you will also stop being informed that your target analysis has failed or succeeded"
                                placement='right-end'
                                classes={{tooltip: classes.tooltip}}
                            >
                                <Switch
                                        checked={isChecked.email ? true : false}
                                        onChange={handleChange}
                                        name="email"
                                        color="primary"
                                        classes={{
                                            switchBase: classes.thumbOff,
                                        }}
                                />
                            </Tooltip>
                    </div>
                    <div className={classes.row}>
                        <Typography
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Platform notifications
                        </Typography>
                            <Tooltip 
                                classes={{tooltip: classes.tooltip}}
                                arrow 
                                title="When notifications are turned off you will also stop being informed that your target analysis has failed or succeeded"
                                placement='right-end'
                            >
                                <Switch
                                        checked={isChecked.notification ? true : false}
                                        onChange={handleChange}
                                        name="notification"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                        classes={{
                                            switchBase: classes.thumbOff,
                                        }}
                                    />
                            </Tooltip>
                    </div>
                    <div className={classes.row}>
                        <Typography
                            variant="subtitle2"
                            className={classes.fieldLabel}
                        >
                            Show tooltips
                        </Typography>
                            <Tooltip 
                                classes={{tooltip: classes.tooltip}}
                                arrow 
                                title="Enable tooltips for the sidebar menu items"
                                placement='right-end'
                            >
                                <Switch
                                        checked={props.tooltipEnabled ? true : false}
                                        onChange={handleChange}
                                        name="tooltips"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        color="primary"
                                        classes={{
                                            switchBase: classes.thumbOff,
                                        }}
                                    />
                            </Tooltip>
                    </div>                    
                </div>
            </div>
            </div>
        </div>
        </>
    )

}

const mapStateToProps = (state /*, ownProps*/) => {
    return {   
        user: state.updates.user,
        userInfo: state.updates.userInfo,
        updatedUserInfo: state.updates.updatedUserInfo
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (data) => {dispatch(getUserInfo(data))},
        updateUserInfo: (data) => {dispatch(updateUserInfo(data))},
        toggleNotifications: (data) => {dispatch(toggleNotifications(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Settings)