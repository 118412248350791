import { useState, cloneElement, useCallback } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import Sidebar from './sidebar';
import Appbar from './appbar'

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: "8px",
    marginLeft: open => open ? "256px" : "60px",
    padding: 0, // MAURO: with Appbar this was "48px 0",
    // add transition
    height: "100%"
  }
 }));


function Layout(props) {
  const [open, setOpen] = useState(false)
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(true)
  const classes = useStyles(open)
 
  const handleTooltip = useCallback(() => {
    setIsTooltipEnabled(!isTooltipEnabled);
  }, [isTooltipEnabled])

  /*
  return (
    <>
      <Appbar position="static"/>
      <Sidebar open={open} setOpen={()=>setOpen(!open)}/>
      <div className={classes.container} open={open}>
        {cloneElement(props.children, { menuOpen: open })}
      </div>
   </>
  );
  */

  return (
    <>
      <Sidebar open={open} setOpen={()=>setOpen(!open)} tooltipEnabled={isTooltipEnabled} />
      <div className={classes.container} open={open}>
        {cloneElement(props.children, { menuOpen: open, tooltipEnabled: isTooltipEnabled, handleTooltip: handleTooltip })}
      </div>
   </>
  );
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
