import {connect} from 'react-redux'
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import clsx from 'clsx'
import { 
    getTarget, 
    getModels, 
    createTarget, 
    getPhenotype,
    resetNewTarget
} from '../actions'
import { 
    Typography, 
    Paper,
    Button,
    Collapse,
    Divider,
    MenuItem,
    TextField,
    Select
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TargetPhenotypes from './targetphenotypes';
import AssignPhenotypes from './assignphenotypes'

import { BiSave } from 'react-icons/bi';
const saveIcon = <BiSave size={20} />;


const useStyles = makeStyles((theme, props) => ({
    mainContainer: {
        minHeight: "100%",
        width: "100%"
    },
    container: {
        margin: "20px 50px 100px 50px",
        minHeight: "70%",
        display: "flex",
        flexDirection: "column",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    titleRow: {
        display: "flex",
        marginLeft: "50px",
        marginTop: "20px",
        alignItems: "baseline"
    },
    options: {
        marginLeft: "40px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.borderColor.onSurface}`,
        alignItems: "center"
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.main
        },
        "&.MuiButton-contained.Mui-disabled": {
            background: theme.palette.secondary.main,
            color: theme.palette.onPrimary.disabled
        }
    },
    row: {
        display: "flex",
        width: "100%",
    },
    select: {
        height: "100%",
        width: "50%",
        marginTop: "20px",
        marginBottom: "40px",
    },
    menuItem: {
        "&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.overlayDragged
        }
    },
    disabledTypography: {
        color: theme.palette.onSurface.disabled,
        marginBottom: "40px",
    },
    sectionTitle: {
        marginTop: "40px",
        marginBottom: "20px",        
    },
    subtitle: {
        color: theme.palette.onSurface.mediumEmphasis,
        width: "50%",
        marginBottom: "20px"
    },
    autoselect: {
        marginBottom: "40px",
        width: "50%"
    },
    primaryBtn: {    
        margin: "10px 100px 10px 0px",
        height: "36px",
        width: "73px",
        "&:hover": {
            background: theme.palette.primary.main
        },
        "&.MuiButton-contained.Mui-disabled": {
            background: theme.palette.primary.main,
            color: theme.palette.onPrimary.disabled
        }
    },
    bottomRow: {
        position: "fixed",
        bottom: 0,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        background: "rgb(245, 245, 250)",
        right: 0
    }
  }));

function NewTarget(props) {
    const classes = useStyles()
    const history = useHistory()
    const [type, setType] = useState("")
    const analysisOptions = ["Target Deconvolution", "Target Validation", "Combination/Multi-Target"]
    const [options, setOptions] = useState([])
    const [model, setModel] = useState(null)
    const [s3Names, setS3Names] = useState([])
    const location = useLocation()
    const [targetId, setTragetId] = useState(null)

    useEffect(()=>{
        props.getModels()
        props.resetNewTarget()
    }, [])

    useEffect(()=>{
        if (location.state?.phenotypeId) {
            props.getPhenotype({phenotypeId: location.state?.phenotypeId})
        }
    }, [location.state?.phenotypeId])

    useEffect(()=>{
        setTragetId(props.newTarget?.id)
    }, [props.newTarget])

    useEffect(()=>{
        let option
        if (location.state?.modelId) {
            setType(analysisOptions[0])
            option = props.models?.filter((option)=>{
                return option.id === location.state?.modelId
            })
            setOptions(option)
            setModel(option?.[0])
        } else if (location.state?.phenotypeId) {
            setType(analysisOptions[0])
            option = props.models?.filter((option)=>{
                return option.id === props.phenotype?.ModeldbId
            })
            setOptions(option)
            setModel(option?.[0])
        } else {
            setOptions(props.models)
         }
    }, [props.models, location.state?.modelId, props.phenotype])  
    
    useEffect(()=>{
        if (targetId) {
            history.push(`/targets/${targetId}`)
            setTragetId(null)
        }
    }, [targetId])

    const handleCreateTarget = () => {
        props.createTarget({
            type: 0, 
            modelId: model.id, 
            phenotypesIds: s3Names.map((name)=>{return name.id}), 
            phenotypes: s3Names.map((name)=>{return name.name})
        })
    }

 return (
     <div className={classes.mainContainer}>
    <Paper
        elevation={0}
        className={classes.column}  
    >       
    <div className={classes.btnRow}>
            <Typography 
                variant="subtitle2" 
                className={classes.options}
            >
  
            </Typography>
        <Button 
            variant="contained" 
            color="primary" 
            disableElevation
            className={classes.secondaryBtn}
            disabled
        >
            <span>{saveIcon}&nbsp;</span>
            <Typography variant="button">
                Save
            </Typography>
        </Button>
    </div>
    <div className={classes.titleRow}>               
        <Typography variant="h5" >
            UNTITLED TARGET
        </Typography>
    </div>
    <div className={classes.container}>
        <div>
        <Typography variant="h6">
            Which type of target analysis do you want to run?
        </Typography>
        <Collapse 
            in={true}
        >
            <div className={classes.row}>
                <Select
                    className={classes.select}
                    fullWidth
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    variant="outlined"
                    value={type}
                    disabled={location.state?.modelId ? true : false}
                    onChange={(e)=>setType(e.target.value)}
                    displayEmpty
                >
                    <MenuItem value="" disabled>
                        Select one option
                    </MenuItem>
                    {analysisOptions.map((option) => (
                        <MenuItem 
                            key={option} 
                            value={option}
                            disabled={option !== "Target Deconvolution"}
                            // className={classes.menuItem}
                            classes={{selected: classes.menuItem}}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </Collapse>
        </div>
        <Divider/>
        <div>
        <Typography 
            variant="h6"
            className={clsx(type ? null : classes.disabledTypography, classes.sectionTitle)}
        >
            Select a model to run your target analysis
        </Typography>
        <Collapse
            in={type ? true : false}
        >
            <Typography 
                variant="body2"
                className={classes.subtitle}
            >
                Type the model name to select it and continue. Once the Model is selected, the following phenotype list will be filtered by it
            </Typography>
            <Autocomplete
                options={options ? options : []}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        variant="outlined" 
                        placeholder="Type model name"
                        className={classes.autoselect}
                    />
                }
                value={model || ""}
                onChange={(event, newValue) => {setModel(newValue)}}  
                fullWidth          
            />
        </Collapse>
        </div>
        <Divider />
        <div>
        {!model && 
            <Typography 
                variant="h6"
                className={clsx(classes.disabledTypography, classes.sectionTitle)}
            >
                Select the phenotypes from the list
            </Typography>}
            <Collapse in={model ? true : false}>
                <TargetPhenotypes 
                    modelId={model?.id} 
                    phenotypeId={location.state?.phenotypeId}
                />
            </Collapse>
            </div>
            <Divider />
            <div>
            <Typography 
                variant="h6"
                className={clsx(props.phenoAssign ? null : classes.disabledTypography, classes.sectionTitle)}
            >
                Check phenotypes assignments
            </Typography>
            <Collapse in={props.phenoAssign ? true : false}>
                <AssignPhenotypes setS3Names={setS3Names} />         
            </Collapse>
            <Divider />
            </div>
        </div>
    <div className={classes.bottomRow}>
            <Button 
                onClick={()=>handleCreateTarget()}
                className={classes.primaryBtn}
                variant="contained"
                color="primary"
                disableElevation
                disabled={(s3Names?.[0] && s3Names?.[1] && s3Names?.[2]) ? false : true}
            >
                <Typography variant="button">
                    Done
                </Typography>
            </Button>
        </div>
    </Paper>
    </div>
 )   
}

const mapStateToProps = (state) => {
    return {
        // target: state.updates.target,
        models: state.updates.models,
        phenoIds: state.updates.phenoIds,
        phenoAssign: state.updates.phenoAssign,
        phenotype: state.updates.phenotype,
        newTarget: state.updates.newTarget
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        // getTarget: (data) => {dispatch(getTarget(data))},
        getModels: (data) => {dispatch(getModels(data))},
        createTarget: (data) => {dispatch(createTarget(data))},
        getPhenotype: (data) => {dispatch(getPhenotype(data))},
        resetNewTarget: (data) => {dispatch(resetNewTarget(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(NewTarget)