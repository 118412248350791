import { useEffect, useState } from 'react';
import { 
    Drawer, 
    Button, 
    Typography, 
    Divider, 
    Tooltip,
    Fade,
    Link,
    IconButton} from '@material-ui/core';   
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router';


import logo from './assets/Netabolics-Logo-W-Ext.png' // mauro

import Notifications from './notifications'
import { 
    Badge, 
    Snackbar,
    Popper, 
    Paper,
    ClickAwayListener 
} from '@material-ui/core';



// mauro: note that these are not used (I ignored srcSelected)
//import menuIcon from './assets/menu-icon.svg'
//import dashboardIcon from './assets/dashboard-icon.svg'
import dashboardIconGreen from './assets/dashboard-icon-green.svg'
//import documentationIcon from './assets/documentation-icon.svg'
import documentationIconGreen from './assets/documentation-icon-green.svg'
//import archiveIcon from './assets/archive-icon.svg'
import archiveIconGreen from './assets/archive-icon-green.svg'
//import modelsIcon from './assets/model-icon.svg'
import modelsIconGreen from './assets/model-icon-green.svg'
//import notebooksIcon from './assets/notebook-icon.svg'
import notebooksIconGreen from './assets/notebook-icon-green.svg'
//import phenotypesIcon from './assets/phenotypes-icon.svg'
import phenotypesIconGreen from './assets/phenotypes-icon-green.svg'
//import settingsIcon from './assets/settings-icon.svg'
import settingsIconGreen from './assets/settings-icon-green.svg'
//import supportIcon from './assets/support-icon.svg'
import supportIconGreen from './assets/support-icon-green.svg'
//import targetsIcon from './assets/target-icon.svg'
import targetsIconGreen from './assets/target-icon-green.svg'
//import logo from './assets/logo.svg'


import Avatar from './avatar';


import { connect } from 'react-redux'
import { getNotifications } from '../actions';



// mauro: change icons
import { BiHelpCircle, BiHome, BiBarcodeReader, BiNetworkChart, BiGroup, BiLayer, BiTargetLock, BiGridAlt, BiExit } from 'react-icons/bi';  
import { AiFillHome, AiOutlineAreaChart, AiOutlineHome, AiOutlineDotChart } from 'react-icons/ai'; 
import { TiChartBarOutline, TiDocumentText } from 'react-icons/ti';
import { TbBarcode, TbChartDots3, TbHome, TbMathFunction } from 'react-icons/tb'; 
import { SiMoleculer, SiStreamrunners } from 'react-icons/si'; 
import { BsJournals, BsGrid3X3GapFill, BsChatDots, BsArchive, BsHouse, BsClipboardData } from 'react-icons/bs'; 
import { FaChartLine, FaArchive, FaRegBell, FaBarcode } from 'react-icons/fa'; 
import { FaArrowsSplitUpAndLeft } from 'react-icons/fa6'; 
import { GiHamburgerMenu, GiBodyHeight } from 'react-icons/gi'; 
import { MdFingerprint, MdOutlineAccountTree, MdOutlineMenu, MdOutlineRocketLaunch, MdClose, MdHelpOutline, MdOutlineContactSupport, MdLogout, MdDashboard, MdOutlineDashboard, MdOutlineSsidChart, MdOutlineSettings } from 'react-icons/md'; 
import { RiBookReadLine, RiFingerprint2Line, RiBookletLine, RiBarChart2Line, RiArchiveDrawerFill, RiArchiveLine, RiFingerprintLine } from 'react-icons/ri'; 
import { BsListColumns, BsPersonLinesFill, BsBarChartLine, BsGear, BsBell } from 'react-icons/bs';
import { VscNotebook, VscMenu } from 'react-icons/vsc'; 
import { ImMenu, ImCross, ImSpinner9, ImSpinner10 } from 'react-icons/im'; 
import { CgClose, CgMenuGridO, CgListTree } from 'react-icons/cg'; 
import { RxExit } from 'react-icons/rx'; 
import { HiFingerPrint, HiDotsVertical } from 'react-icons/hi'; 
import { PiGraphFill } from 'react-icons/pi'; 
import { VscOpenPreview } from 'react-icons/vsc'; 
import { GoProjectTemplate } from 'react-icons/go'; 
import { FontDownloadRounded, NavigateBeforeSharp } from '@material-ui/icons';
import { CiMenuKebab } from 'react-icons/ci'; 
import { SlNotebook } from 'react-icons/sl'; 
import { GrChat } from 'react-icons/gr'; 
import { IoIosLogOut } from 'react-icons/io'; 
import { LuLogOut, LuWorkflow } from 'react-icons/lu'; 
import { VscSettings } from "react-icons/vsc";
import { HiMiniFingerPrint } from "react-icons/hi2";
import { HiOutlineArrowPathRoundedSquare, HiOutlineHome } from 'react-icons/hi2'; 
import { PiTreeStructureLight, PiDna, PiDnaLight, PiVirus } from "react-icons/pi";
import { IoBookOutline } from "react-icons/io5";
//import { HiFingerPrint } from "react-icons/hi2";
import { BsReverseListColumnsReverse } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
const dashboardIcon = <HiOutlineHome size={23} />; 
const modelsIcon = <PiTreeStructureLight size={23} />; 
const phenotypesIcon = <TbBarcode size={25} />;
const targetsIcon = <TbMathFunction />;
//const notebooksIcon = <AiOutlineDotChart size={22} />;
const notebooksIcon = <BsJournals />;
const archiveIcon = <BsArchive />;
const documentationIcon = <IoBookOutline size={23} />;
const supportIcon = <GrChat size={19} />; // <MdOutlineContactSupport />;
const settingsIcon = <BsGear />;
const menuIcon =  <MdOutlineMenu size={22} />; // MdOutlineMenu
const menuIconOpen =  <CgClose size={22} />;
const notificationsIcon = <BsBell />
const exitIcon = <LuLogOut />




const useStyles = makeStyles((theme) => ({
    paper: {
     background:  theme.palette.background.dark,
     display: "flex",
     flexDirection: "column",
     justifyContent: "space-between",
     overflow: "hidden",
     border: "none",
     borderTopLeftRadius: "0px",
     borderBottomLeftRadius: "0px"
    },
    openDrawer: {
        width: "256px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
    },
    closedDrawer: {
        width: "60px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
    },
    text: {
        textTransform: "unset",
    },
    logobutton: {
        height: "48px",
        display: "flex",
        justifyContent: "flex-start",
        padding: 0,
        borderLeft: "4.2px solid transparent",
        color: theme.palette.primary.light,
    },    
    button: {
        height: "48px",
        display: "flex",
        justifyContent: "flex-start",
        padding: 0,
        borderLeft: "4.2px solid transparent",
        color: theme.palette.primary.light,
        "&:hover": {
            backgroundColor: theme.palette.primary.black
        }
    },
    column: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    icon: {
        width: "60px",
        display: "flex",
        justifyContent: "center",
        color: theme.palette.primary.light
    },
    divider: {
        color: "blue",
        height: "1px",
        width: "100%"
    },
    buttonRoot: {
        background: theme.palette.primary.black, // theme.palette.background.default,
        borderRadius: "0px",
        borderLeft: "4.2px solid",
        borderLeftColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    buttonRootDark: {
        background: theme.palette.primary.black,
        borderRadius: "0px",
        borderLeft: "4.2px solid",
        borderLeftColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    logo: {
        marginBottom: "20px",
        width: "100%"
    },
    itemTopSelected: {
        color: theme.palette.primary.main
    },
    itemTopNotSelected: {
        color: theme.palette.primary.light
    },
    itemBottomSelected: {
        color: theme.palette.primary.main,
    },
    itemBottomNotSelected: {
        color: theme.palette.primary.light
    },
    smallDivider: {
        height: "32px"
    }, 
    badge: {
        width: "44px",
        display: "flex",
        justifyContent: "center",
        marginLeft: "8px",
        marginRight: "8px",
        cursor: "pointer"
    },
  }));



  const tooltipText = (button) => {
    return (
        <div style={{ fontSize:"16px" }}>
            <b>{button.text}</b>
            <p style={{ fontSize:"14px", marginTop: "10px" }} dangerouslySetInnerHTML={{__html: button.tooltip}} />
            {button.refto !== null 
            ? 
            <>
                <p style={{ fontSize:"12px", marginTop: "20px" }}>
                    Read more about this topic and how we handle {button.text.toLowerCase()} on &nbsp;
                    <b><a href={'https://netabolics.ai/' + button.refto} target="_blank" rel="noreferrer">netabolics.ai &nbsp;<FiExternalLink size={16} /></a></b>
                </p>
            </>
            :
            <></>
            }
        </div>
    )
}  


function Sidebar(props) {
    const classes = useStyles();
    const [selectedTop, setSelectedTop] = useState(null)
    const [selectedBottom, setSelectedBottom] = useState(null)
    const history = useHistory()
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    
    // I have put this inside Sidebar because I want to use props
    const SidebarTooltip = withStyles(theme => ({
        tooltip: {
            fontFamily: "Sintony",
            letterSpacing: "normal",
            lineHeight: "1.2",
            minWidth: "550px",
            maxWidth: "550px",
            padding: "20px 20px 20px 30px",
            borderRadius: "10px",
            marginLeft: "5px",
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.background.light,
            opacity: "1",
            '& .MuiTooltip-arrow': {
                color: theme.palette.background.dark
            },
            "& .MuiTooltip-arrow:before": {
                boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.2)",
            },
            "& a": {
                textDecoration: "none",
                color: "#ffffff"
            },   
            visibility: `${props.tooltipEnabled ? "visible" : "hidden"}`
        }
      }), { withTheme: true })(Tooltip); 


    useEffect(()=>{
        props.getNotifications()
    }, [])

    useEffect(()=>{
        let pathName
        let index
        if (location.pathname === '/') {pathName = "/dashboard"} else {pathName = location.pathname};
        index = buttons.top.map(function(o) { return pathName.startsWith(o.link); }).indexOf(true);
        if (index !== -1) {
            setSelectedTop(index);
            setSelectedBottom(null);
        } else {
            index = buttons.bottom.map(function(o) { return o.link; }).indexOf(pathName);
            if (index !== -1) {
                setSelectedBottom(index);
                setSelectedTop(null);
            }
        }
    }, [location.pathname])    

    const handleToggle = (e) => {
        setIsOpen(!isOpen)
        setAnchorEl(e.currentTarget)
    }

    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('permissions')
        window.location.reload();
      }    

    const dashboardTooltip      = "Your environment at a glance. Here you can view the current status "
                                + "of your simulations and analyses, whether pending, in progress, or completed."

    const modelsTooltip         = "Specify biological networks. We recommend using the latest Netabolics&reg; model "
                                + "integrating metabolic reaction network (MRN), signal transduction network (STN), "
                                + "and gene regulatory network (GRN)."

    const phenotypesTooltip     = "Define cellular phenotypes. Here you can upload multiOMICs datasets or manually specify perturbations. "
                                + "The model parametrization of the corresponding cellular state(s) will be automatically performed "
                                + "by the platform using an iterative self-consistent method."

    const simulationsTooltip    = "Run simulations. Just select your objective and the biological network activity "
                                + "will be simulated using ordinary differential equation (ODE) solvers and optimized using "
                                + "Deep Reinforcement Learning (DRL)."

    const resultsTooltip        = "Explore results. Rapidly examine the main outcomes of the simulations, and download the "
                                + "output data for any additional analysis you might want to perform offline."

    const archiveTooltip        = "Store platform assets. Here you can temporarily keep models, phenotypes, simulations, "
                                + "and results for later use. Sharing is possible through the Public Library."

    const documentationTooltip  = "Learn about how to use the platform."

    const supportTooltip        = "Contact us for assistance."

    const settingsTooltip       = "Edit or view preferences, including your profile information, permissions and notifications."

    const notificationsTooltip  = "See unread messages."

    const signoutTootlip        = "End current session."

    const buttons = {
        top: [
            {src: dashboardIcon, srcSelected: dashboardIconGreen, text: "Dashboard", link: "/dashboard", tooltip: dashboardTooltip, refto: null},
            {src: modelsIcon, srcSelected: modelsIconGreen, text: "Models", link: "/models", tooltip: modelsTooltip, refto: "docs/models"},
            {src: phenotypesIcon, srcSelected: phenotypesIconGreen, text: "Phenotypes", link: "/phenotypes", tooltip: phenotypesTooltip, refto: "docs/phenotypes"},
            {src: targetsIcon, srcSelected: targetsIconGreen, text: "Simulations", link: "/targets", tooltip: simulationsTooltip, refto: "docs/simulations"},
            {src: notebooksIcon, srcSelected: notebooksIconGreen, text: "Results", link: "/notebooks", tooltip: resultsTooltip, refto: null},
            {src: archiveIcon, srcSelected: archiveIconGreen, text: "Archive", link: "/archive", tooltip: archiveTooltip, refto: null},
        ],
        bottom: [          
            {src: documentationIcon, srcSelected: documentationIconGreen, text: "Documentation", link: "/documentation", tooltip: documentationTooltip, refto: null},
            {src: supportIcon, srcSelected: supportIconGreen, text: "Support", link: "/support", tooltip: supportTooltip, refto: null},
            {src: settingsIcon, srcSelected: settingsIconGreen, text: "Settings", link: "/settings", tooltip: settingsTooltip, refto: null}
        ],
        extra: [
            {src: notificationsIcon, srcSelected: null, text: "Notifications", link: null, tooltip: notificationsTooltip, refto: null},
            {src: exitIcon, srcSelected: null, text: "Sign Out", link: null, tooltip: signoutTootlip, refto: null}
        ]
    }

    const selectTop = (button) => {
        setSelectedTop(buttons.top.indexOf(button))
        setSelectedBottom(null)
        history.push(button.link)
    }

    const selectBottom = (button) => {
        setSelectedBottom(buttons.bottom.indexOf(button))
        setSelectedTop(null)
        history.push(button.link)
    }      



    return (
        <Drawer 
            variant="permanent"
            classes={{
                paper: clsx(classes.paper, {
                    [classes.openDrawer]: props.open,
                    [classes.closedDrawer]: !props.open,
                }),
            }}
        >
            <div className={classes.column} >
                <Button 
                    className={clsx(classes.logobutton, classes.logo)}
                    classes={{text: classes.text}} 
                    disableRipple
                    startIcon={
                        <span className={classes.icon}>
                            {props.open ? menuIconOpen : menuIcon}
                        </span>
                    }
                    onClick={()=>props.setOpen()}>                    
                        <img height="32px" src={logo} alt="logo" />
                        {/*
                        <Typography variant="subtitle2">
                            <b>Netabolics</b><sup>&reg;</sup>
                        </Typography>                  
                        */}      
                </Button>
                <Divider className={classes.smallDivider} />
                {
                    buttons.top.map((button) => {
                        return (
                            <SidebarTooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={tooltipText(button)}                              
                                placement="right-start"      
                                arrow          
                                interactive  
                                key={button.text}                                                 
                            >
                                <Button 
                                    className={classes.button}
                                    classes={{
                                        text: classes.text, 
                                        root: clsx({
                                            [classes.buttonRoot]: selectedTop === buttons.top.indexOf(button), 
                                            [classes.text]: !selectedTop === buttons.top.indexOf(button),
                                        })
                                }} 
                                fullWidth
                                onClick={()=>selectTop(button)}
                                startIcon={
                                    <span className={classes.icon}>
                                            <span 
                                                className={selectedTop === buttons.top.indexOf(button) ? classes.itemTopSelected : classes.itemTopNotSelected} 
                                                classes={{startIcon: classes.icon}} 
                                                >
                                            {button.src}
                                            </span>
                                        </span>
                                        }
                                        >
                                            <Typography variant="subtitle2">
                                                {button.text}
                                            </Typography>
                                </Button>
                            </SidebarTooltip>
                        )
                    })
                }
            </div>
            <div className={classes.column}>
                <Divider />
                {
                    buttons.bottom.map((button) => {
                        return (
                            <SidebarTooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={tooltipText(button)}
                                placement="right"       
                                arrow   
                                interactive
                                key={button.text}
                            >
                                <Button 
                                    className={classes.button}
                                    classes={{
                                        text: classes.text, 
                                        root: clsx({
                                            [classes.buttonRootDark]: selectedBottom === buttons.bottom.indexOf(button), 
                                            [classes.text]: !selectedBottom === buttons.bottom.indexOf(button),
                                        })
                                    }} 
                                    fullWidth
                                    onClick={()=>selectBottom(button)}
                                    startIcon={
                                        <span className={classes.icon}>
                                            <span 
                                                className={selectedBottom === buttons.bottom.indexOf(button) ? classes.itemBottomSelected : classes.itemBottomNotSelected} 
                                                classes={{startIcon: classes.icon}} 
                                                >
                                            {button.src}
                                            </span>
                                        </span>
                                        }
                                >
                                        <Typography variant="subtitle2">
                                            {button.text}
                                        </Typography>
                                </Button>
                            </SidebarTooltip>
                        )
                    })
                }
                <Divider className={classes.smallDivider} />

                <SidebarTooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={tooltipText(buttons.extra[0])}
                    placement="right-end"           
                    arrow    
                    interactive                      
                >
                    <Button 
                        className={classes.button} 
                        classes={{
                            text: classes.text, 
                            root: clsx({
                                [classes.buttonRootDark]: false, 
                                [classes.text]: false
                            })
                        }}    
                        style={{cursor: "default"}}             
                        fullWidth
                        disableRipple
                        key={notificationsIcon}
                        startIcon={                        
                            <Badge 
                                className={classes.badge}
                                badgeContent={props.unreadNotifications?.length == null ? 0 : props.unreadNotifications?.length} 
                                color="primary"
                                onClick={handleToggle}      
                                showZero         
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}           
                                overlap="circular" 
                                max={99}        
                            >
                            {notificationsIcon}                       
                            </Badge>                           
                        }                    
                    >
                        <Typography variant="subtitle2">
                            {buttons.extra[0].text}
                        </Typography>                    
                    </Button>
                </SidebarTooltip>         
                {/*            
                <Popper 
                    open={isOpen}
                    anchorEl={anchorEl}
                    transition
                    placement="right"
                >     
                    <Notifications 
                        isOpen={isOpen} 
                        unreadNotifications={props.unreadNotifications}
                        sidebarOpen={props.open}
                    />
                </Popper>
                */}
                <Snackbar 
                    open={isOpen && props.unreadNotifications>0}
                    message={
                        <Notifications 
                            isOpen={isOpen} 
                            unreadNotifications={props.unreadNotifications}
                        />
                    } 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={3000}
                />                    

                <SidebarTooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={tooltipText(buttons.extra[1])}
                    placement="right-end"
                    arrow   
                    interactive              
                >
                    <Button 
                        className={classes.button} 
                        classes={{
                            text: classes.text, 
                            root: clsx({
                                [classes.buttonRootDark]: false, 
                                [classes.text]: false
                            })
                        }}                 
                        fullWidth
                        key={exitIcon}      
                        onClick={logout}
                        startIcon={
                            <span className={classes.icon}>
                                <span 
                                    className={classes.itemBottomNotSelected} 
                                    classes={{startIcon: classes.icon}} 
                                    >
                                {exitIcon}
                                </span>
                            </span>
                            }                              
                    >
                        <Typography variant="subtitle2">
                            {buttons.extra[1].text}
                        </Typography>   
                    </Button>
                </SidebarTooltip>

            </div>
        </Drawer>
    )
}

const mapStateToProps = (state ) => {
    return {
        unreadNotifications: state.updates.unreadNotifications
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: (data) => {dispatch(getNotifications(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
