
export const CodeId = (props) => {
    const codes = {
        model: "M",     // M for Models
        phenotype: "P", // P for Phenotypes
        target: "S",    // S for Simulations
        notebook: "R"   // R for Results
    }

    return(
        <>
            {codes[props.name] + "-" + props.id?.toString().padStart(8,"0")}
        </>
    )
}