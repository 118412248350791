import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getModel, updateModel } from '../actions'
import { useParams, useHistory } from 'react-router-dom'
import { 
    Paper,
    Typography,
    TextareaAutosize,
    Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { parseDate, parseTime } from '../commons'
import clsx from 'clsx'
import dataIcon from './assets/data.svg'

import { CodeId } from '../commons/codeid';

const useStyles = makeStyles((theme, props) => ({
    row: {
        display: "flex",
        width: "100%",
        alignItems: "baseline",
        marginBottom: "20px"
    },
    icon: {
        marginLeft: "30px"
    },
    root: {
        minWidth: "250px",
        color: theme.palette.onSurface.mediumEmphasis
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        paddingRight: "50px",
        paddingLeft: "50px",
        marginTop: "20px"
    },
    textarea: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        resize: "none",
        width: "100%",
        outline: theme.palette.borderColor.onSurface,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        padding: "15px",
        borderRadius: "10px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    },
    primaryBtn: {
        justifySelf: "flex-end",
        "&:hover": {
            background: theme.palette.primary.main
        }
    }, 
    fieldOutline: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        width: "646px",
        height: "40px"
    },
    chip: {
        background: theme.palette.background.dark,
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.primary.variant,
            color: "white",
        },
        marginRight: "10px",
        height: "24px"
    },
  }));

function ModelInfo(props) {
    const params = useParams()
    const classes = useStyles()
    const history = useHistory()

    useEffect(()=>{
        props.getModel({modelId: params.modelId})
    }, [])

    return (
        <Paper
            elevation={0}
            className={classes.container}
        >
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}} >
                    Description
                </Typography>
                <TextareaAutosize 
                    className={classes.textarea} 
                    minRows={4}
                    value={props.description || ""}
                    onChange={(e)=>props.handleInputChange(e)}
                    name="description"
                    placeholder="Type a description"
                />
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Used Resources
                </Typography>
            </div>            
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Created By
                </Typography>
                <Typography variant="body1" >                   
                    {props.user?.name} {props.user?.lastName} ({props.user?.institution}, {props.user?.email} )
                </Typography>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    On
                </Typography>
                <Typography variant="body1" >
                    {parseDate(props.model?.createdAt)} - {parseTime(props.model?.createdAt)}
                </Typography>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Last Modified
                </Typography>
                <Typography variant="body1" >
                    {parseDate(props.model?.updatedAt)} - {parseTime(props.model?.updatedAt)}
                </Typography>
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Model ID
                </Typography>
                <TextareaAutosize 
                    className={classes.textarea} 
                    minRows={1}
                    value={props.model?.id || ""}
                    onChange={(e)=>props.handleInputChange(e)}
                    name="modelID"
                    disabled
                />
            </div>
            <div className={classes.row}>          
                <Typography variant="body1" classes={{root: classes.root}}>
                    Notes
                </Typography>
                <TextareaAutosize 
                    className={classes.textarea} 
                    minRows={2}
                    value={props.notes || ""}
                    onChange={(e)=>props.handleInputChange(e)}
                    name="notes"
                    placeholder="Type your notes"
                />
            </div>
        </Paper>
        
    )
}

const mapStateToProps = ( state ) => {
    return {   
        model: state.updates.model,
        user: state.updates.user
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getModel: (data) => {dispatch(getModel(data))},
        updateModel: (data) => {dispatch(updateModel(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ModelInfo)