import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { 
    Typography, 
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    avatar: {
        borderRadius: "50%",
        height: "48px",
        width: "48px",
        background: theme.palette.primary.variant,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "default",
    },
    initials: {
        fontSize: "20px",
        color: "#ffffff"
    }
}))

function Avatar(props) {
    const classes = useStyles();
    const history = useHistory()

    return (
        <>
            <div className={classes.avatar} onClick={()=>history.push("/settings")}>
                <Typography className={classes.initials}>
                    {props.user && props.user.name[0]}{props.user && props.user.lastName[0]}
                </Typography>
            </div>
      </>
    )
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        user: state.updates.user
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);