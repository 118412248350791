import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getPhenotype } from '../actions'
import { useParams, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { 
    Paper,
    Typography,
    TextareaAutosize,
    Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { parseDate, parseTime } from '../commons'
import PhenoStatus from './phenostatus'
import dataIcon from './assets/data.svg'

import { CodeId } from '../commons/codeid';

const useStyles = makeStyles((theme, props) => ({
    row: {
        display: "flex",
        width: "100%",
        alignItems: "baseline",
        marginBottom: "20px"
    },
    root: {
        minWidth: "150px",
        marginRight: "50px",
        color: theme.palette.onSurface.mediumEmphasis
    },
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        paddingRight: "50px",
        paddingLeft: "50px",
        marginTop: "20px"
    },
    dataColumn: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
    },
    textarea: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        resize: "none",
        width: "100%",
        outline: theme.palette.borderColor.onSurface,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        padding: "15px",
        borderRadius: "10px"
    },   
    chip: {
        background: theme.palette.background.dark,
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.primary.variant,
            color: "white",
        },
        marginRight: "10px",
        height: "24px"
    },
  }));

function PhenotypeInfo(props) {
    const params = useParams()
    const classes = useStyles()
    const history = useHistory()

    useEffect(()=>{
            props.getPhenotype({phenotypeId: params.phenotypeId});
    }, [])

    return (
        <Paper
            elevation={0}
            className={classes.container}
        >          
            <div className={classes.dataColumn}>
                <div className={classes.row}>
                    <PhenoStatus send={props.send}/>
                </div>                  
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}} >
                        Description
                    </Typography>
                    <TextareaAutosize 
                        className={classes.textarea} 
                        minRows={4}
                        value={props.description || ""}
                        onChange={(e)=>props.handleInputChange(e)}
                        name="description"
                        placeholder="Type a description"
                    />
                </div>          
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        Used Resources
                    </Typography>
                    <Button
                        className={clsx(classes.details, classes.chip)}
                        endIcon={<img src={dataIcon} alt="data icon"/>}
                        onClick={()=>history.push(`/models/${props.phenotype?.ModeldbId}`)}
                    >
                        <Typography variant="caption">
                            <CodeId name="model" id={props.phenotype?.ModeldbId} />
                        </Typography>
                    </Button>
                </div>
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        Created By
                    </Typography>
                    <Typography variant="body1" >                   
                        {props.user?.name} {props.user?.lastName} ({props.user?.institution}, {props.user?.email} )
                    </Typography>
                </div>
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        On
                    </Typography>
                    <Typography variant="body1" >
                        {parseDate(props.phenotype?.createdAt)} - {parseTime(props.phenotype?.createdAt)}
                    </Typography>
                </div>
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        Last Modified
                    </Typography>
                    <Typography variant="body1" >
                        {parseDate(props.phenotype?.updatedAt)} - {parseTime(props.phenotype?.updatedAt)}
                    </Typography>
                </div>
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        Phenotype ID
                    </Typography>
                    <TextareaAutosize 
                        className={classes.textarea} 
                        minRows={1}
                        value={props.phenotype?.id || ""}
                        onChange={(e)=>props.handleInputChange(e)}
                        name="phenotypeID"
                        disabled
                    />
                </div>
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        {/* add pertubations */}
                    </Typography>
                </div>
                <div className={classes.row}>          
                    <Typography variant="body1" classes={{root: classes.root}}>
                        Notes
                    </Typography>
                    <TextareaAutosize 
                        className={classes.textarea} 
                        minRows={2}
                        value={props.notes || ""}
                        onChange={(e)=>props.handleInputChange(e)}
                        name="notes"
                        placeholder="Type your notes"
                    />
                </div>
            </div>
        </Paper>
        
    )
}

const mapStateToProps = ( state ) => {
    return {   
        phenotype: state.updates.phenotype,
        user: state.updates.user
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getPhenotype: (data) => {dispatch(getPhenotype(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(PhenotypeInfo)