import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { useParams } from 'react-router';
import { getModel, downloadFile, getTarget, runTarget } from '../actions'
import { 
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core'
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import exportIcon from './assets/export-icon.svg'
import targetIcon from './assets/target-icon-white.svg'
import targetIconDisabled from './assets/target-icon-disabled.svg'

import { FiPlay } from 'react-icons/fi';  
import { FiDownload } from 'react-icons/fi'; 
const downloadIcon = <FiDownload size={18} />;
const targetsRunIcon = <FiPlay />;



const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        backgroundColor: theme.palette.background.row,
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
    },
    column: {
        display: "flex",
        flexDirection: "column"
    },
    statusColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        paddingLeft: "50px",
        marginTop: "10px"
    },
    downloadBtn: {
        border: `1px solid ${theme.palette.borderColor.onSurface}`,
        marginBottom: "20px",
        height: "36px",
        color: theme.palette.primary.main
    },
    row: {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px"
    },
    status: {
        marginLeft: "10px"
    },
    pending: {
        color: theme.palette.surface.overlay
    },
    pendingStatus: {
        color: theme.palette.onSurface.disabled
    },
    exportIcon: {
        marginRight: "10px"
    },
    primaryBtn: {    
        height: "36px",
        margin: "5px 0px",
        "&:hover": {
            background: theme.palette.primary.main
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.onPrimary.disabled 
        }      
    },
    secondaryBtn: {
        height: "48px",
        "&:hover": {
            background: theme.palette.secondary.default
        },
        marginRight: "10px"
    },    
    downloadBtn: {
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        height: "48px",
        "&:hover": {
            background: theme.palette.primary.main,
            color: theme.palette.background.default
        },
        marginRight: "10px"        
    },
    mediumEmphasis: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    icon: {
        color: theme.palette.primary.contrastText,
        marginRight: "5px"
    },
    iconDisabled: {
        color: theme.palette.primary.light,
        marginRight: "5px"
    } 
  }));

function TargetStatus(props) {
const params = useParams()
const classes = useStyles()
const [status, setStatus] = useState()

useEffect(()=>{
    props.getTarget({targetId: params.targetId})
}, [props.notebook])

useEffect(()=>{
    setStatus(props.target?.status)
}, [props.target])

const getStatusIcon = () => {
    if (props.status === "done" || status === 1) {
        return <CheckIcon color="primary"/>
    } else if (props.status === "pending" || status === 0) {
        return <CircularProgress variant="determinate" value={100} className={classes.pending}/>
    } else if (props.status === "in progress" || status === 3) {
        return <CircularProgress variant="indeterminate" color="secondary"/>
    } else if (props.status === "error" || status === 2) {
        return <CloseIcon color="error"/>
    }

}

const getStatus = () => {
    if (status === 1) {
        return <Typography 
                    className={classes.status} 
                    color="primary" 
                    variant="subtitle2"
                >
                    DONE
                </Typography>
    } else if (status === 0) {
        return <Typography 
                    className={clsx(classes.status, classes.pendingStatus)} 
                    variant="subtitle2"
                >
                    PENDING
                </Typography>
    } else if (status === 2) {
        return <Typography 
                    className={classes.status} 
                    color="error" 
                    variant="subtitle2"
                >
                    FAILED
                </Typography>
    } else {
        return <Typography 
                    className={classes.status} 
                    color="secondary" 
                    variant="subtitle2"
                >
                    RUNNING
                </Typography>
    }

}

const handleDownload = (filename) => {
    props.downloadFile({key: filename+"data_prefix_sims.xlsx"})
}

    return (
        <div className={classes.statusColumn}>
            {/*
            <div className={classes.row}>
                    {getStatusIcon()} {getStatus()}      
            </div>
            */}
            <div className={classes.row}>
                <Button 
                    onClick={()=>{props.runTarget({targetId: params.targetId})}}
                    className={classes.secondaryBtn} 
                    color="primary" 
                    variant="contained" 
                    disableElevation
                    disabled={status === 3 ? true : false}
                >
                    <span className={
                        status === 3 ? classes.iconDisabled : classes.icon
                    }>
                        {targetsRunIcon}&nbsp;
                    </span>
                    <Typography variant="button">
                        Run Simulation
                    </Typography>
                </Button>
                <Button
                    disabled={
                        props.target?.status === 1
                        ? 
                        false 
                        : 
                        true
                    }
                    className={classes.downloadBtn}
                    color="primary"                     
                    variant="contained" 
                    disableElevation                    
                    onClick={()=>handleDownload(props.target?.s3name)}
                >
                    {downloadIcon} &nbsp;
                    <Typography variant="button">
                        Simulations
                    </Typography>
                </Button>     
            </div>      
        </div>
    )
}

const mapStateToProps = (state ) => {
    return {   
        model: state.updates.model,
        target: state.updates.target,
        notebook: state.updates.notebook
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getModel: (data) => {dispatch(getModel(data))},
        downloadFile: (data) => {dispatch(downloadFile(data))},
        getTarget: (data) => {dispatch(getTarget(data))},
        runTarget: (data) => {dispatch(runTarget(data))}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(TargetStatus)