import { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import clsx from 'clsx'
import { 
    getNotebooks,
    updateNotebook, 
    archiveNotebook,
    deleteNotebook,
    resetWarning
 } from '../actions'
import { 
    TableContainer, 
    Table, 
    TableHead, 
    TableCell, 
    TableRow, 
    TableBody, 
    Button, 
    Typography, 
    Paper,
    Collapse,
    TablePagination,
    TableSortLabel,
    TextField,
    TableFooter
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import actionsIcon from './assets/actions-icon.svg'
import Search from './search';
import ActionsMenu from './actionsmenu'
import DialogBox from './dialog';
import { parseDate, parseTime } from '../commons'
import orderBy from 'lodash/orderBy';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import dataIcon from './assets/data.svg'

import { CodeId } from '../commons/codeid';

import { BsCalendar3 } from 'react-icons/bs';
const dateSymbol = <BsCalendar3 />;



const useStyles = makeStyles((theme) => ({
    container: {
        padding: "60px 50px"
    },
    table: {
        border: "0px solid",
        borderColor: theme.palette.borderColor.onSurface,
        marginTop: "10px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        overflow: "hidden"
    },
    root: {
        border: "0px",
        "&:nth-of-type(4n+1)": {
            backgroundColor: theme.palette.background.row,
        },
        "&:hover": {
            background: theme.palette.background.rowHover,
        }
    },
    rootExpanded: {
        background: theme.palette.background.rowHover,
        verticalAlign: "top",
        height: "52px",
        whiteSpace: "wrap"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around"
    },
    row: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
        width: "50%",
    },
    cells: {
        border: "0px",
        height: "52px",
    },
    visible: {
        visibility: "visible",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    hidden: {
        visibility: "hidden",
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "baseline"
    },
    cellCollapsed: {
        paddingBottom: 0, 
        paddingTop: 0,
        borderBottom: 0
    },
    rowExpanded: {
        padding: "20px",
        background: theme.palette.background.rowHover,
    },
    rowActive: {
        background: theme.palette.background.rowHover,
    },
    pagination: {
        borderTop: "1px solid",
        borderColor: theme.palette.borderColor.onSurface,
        color: theme.palette.onSurface.mediumEmphasis
    },
    statusRunning: {
        color: theme.palette.secondary[600]
    },
    time: {
        color: theme.palette.onSurface.disabled
    },
    details: {
        marginLeft: "50px",
        marginBottom: "20px"
    },
    btnRow: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        height: "48px",
    },
    options: {
        marginLeft: "40px"
    },
    primaryBtn: {
        height: "48px",
        marginBottom: "10px",
        justifySelf: "flex-end",
        "&:hover": {
            background: theme.palette.primary.main
        }
    },
    notes: {
        overflow: "hidden",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        border: "0px",
        alignSelf: "flex-start"
    },
    chip: {
        background: theme.palette.background.dark,
        color: "white",
        borderRadius: "unset",
        "&:hover": {
            background: theme.palette.primary.variant,
            color: "white",
        },
        marginRight: "10px",
        height: "24px"
    },
    expandIcon: {
        alignSelf: "center",
    },
    iconRoot: {
        height: "0.8em"
    },
    rowName: {
        color: theme.palette.onSurface.mediumEmphasis
    },
    listTitle: {
        fontFamily: theme.typography.listTitle.fontFamily,
        fontWeight: theme.typography.listTitle.fontWeight,
        fontSize: theme.typography.listTitle.fontSize,
        lineHeight: theme.typography.listTitle.lineHeight,
        letterSpacing: theme.typography.listTitle.letterSpacing,
        color: theme.palette.onSurface.mediumEmphasis,
        marginBottom: "20px"
    },
    actionsBtn: {
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    detailsLabel: {
        color: theme.palette.onSurface.mediumEmphasis,
        fontSize: "12px"
    },
    tableHead: {
        backgroundColor: theme.palette.background.dark,
        height: "60px"
    },
    tableHeadText: {
        color: theme.palette.primary.contrastText,  
        color: theme.palette.primary.light,
        "&:hover": {
            color: theme.palette.primary.light
        }        
    },
  }));


function NotebookList(props) {
    const classes = useStyles()
    const history = useHistory()
    const [rows, setRows] = useState([])
    const [hover, setHover] = useState(null)
    const [isOpen, setIsOpen] = useState(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortedBy, setSortedBy] = useState()
    const [direction, setDirection] = useState()
    const [rename, setRename] = useState()
    const [name, setName] = useState("")
    const [rowId, setRowId] = useState()
    const [completed, setCompleted] = useState()

    useEffect(()=>{
        props.getNotebooks()
    }, [props.updatedNotebook])

    useEffect(()=>{
        const completed = props.notebooks?.filter((notebook)=>{
            return (notebook.Target?.status === 1 || notebook.Target?.status === 2)
        })
        setCompleted(completed)
    }, [props.notebooks])

    useEffect(()=>{   
        setRows(completed)
    }, [completed])

    const getMatches = (matches, input) => {
        setRows(input !== "" ? matches : completed)
    }

    // change based on real data
    const getType = (type) => {
        if (type === 0) {
            return <Typography variant="body2" >Target Deconvolution</Typography>
        }  
    }

    const handleToggle = (e, id) => {
        setRowId(id)
        setIsMenuOpen(!isMenuOpen)
        setAnchorEl(e.currentTarget)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const handleSortingDirection = (name) => {
        if (sortedBy !== name) {
            setSortedBy(name)
            setDirection('desc')
        } else {
            if (direction === 'asc') {
                setDirection('desc')
            } else {
                setDirection('asc')
            }            
        }
        handleSorting(name)
    }

    const handleKeyPress = (e, id) => {
        if (e.key === 'Enter') {
            props.updateNotebook({notebookId: id, name})
            setName("")
            setRename(false)
        }
    }

    const columns = [
        "Results Name", 
        "Simulation Name", 
        "Created", 
        "Analysis Type", 
        "Actions"
    ]

    const handleSorting = (name) => {
        // SEE ABOVE FOR THE COLUMN NAMES
        const rowsArray = rows
        let orderedRows
        if (name === "Results Name") {
            orderedRows = orderBy(rowsArray, [row => row.name.toLowerCase()], [direction])
        } else if (name === "Simulation Name") {
            orderedRows = orderBy(rowsArray, [row => row.type], [direction])
        } else if (name === "Created") {
            orderedRows = orderBy(rowsArray, [row => row.createdAt], [direction])
        } else if (name === "Analysis Type") {
            orderedRows = orderBy(rowsArray, [row => row.ssdistribution], [direction])           
        } 
        setRows(orderedRows)
    }

    const handleExpand = (rowId) => { 
        if (isOpen !== rowId)  {
            setIsOpen(rowId)
        } else {
            setIsOpen(null)
        }
    }

    const archiveNotebook = (id) => {
        props.archiveNotebook({notebookId: id})
    }


    return (
        <>
        <div className={classes.container}>
        <Typography className={classes.listTitle}>
            Results List
        </Typography>
        <Search rows={completed} sendMatches={getMatches} type="notebooks"/>
        <TableContainer component={Paper} elevation={0}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow >
                       {columns.map((column)=>{
                           return (
                               <TableCell 
                                    variant="head"
                                    align={column === "Actions" ? "center" : "left"} 
                                    key={column}                                   
                                >
                                    <Typography variant="subtitle2" className={classes.tableHeadText}>
                                        {column === "Created" ? dateSymbol : null} 
                                        &nbsp;                                           
                                        {column}
                                        {column !== "Actions" ?<TableSortLabel
                                            className={classes.tableHeadText}
                                            direction={direction} 
                                            onClick={()=>{handleSortingDirection(column)}}                                           
                                        /> : null}
                                    </Typography>
                                </TableCell>
                           )
                       })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{
                        return(
                            <Fragment key={row.id}>
                                <TableRow 
                                    key={row.id} 
                                    classes={{root: clsx({
                                        [classes.rootExpanded]: isOpen === row.id,
                                        [classes.root]: isOpen !== row.id
                                    })}}   
                                    onMouseEnter={()=>{setHover(row.id)}} 
                                    onMouseLeave={()=>{setHover(null)}}>
                                        <TableCell 
                                            className={clsx(classes.column, classes.cells)} 
                                            >
                                            {rename === row.id ? 
                                                <TextField 
                                                    variant="outlined"
                                                    label="Type new name"
                                                    value={name}
                                                    onKeyPress={(e)=>handleKeyPress(e, row.id)} 
                                                    onChange={(e)=>setName(e.target.value)}
                                                    size="small"
                                                />
                                                :
                                                <>
                                                <Typography variant="body2" >
                                                {row.name}
                                                </Typography>
                                            <Typography 
                                                variant="caption"
                                                className={
                                                    (hover === row.id && 
                                                    isOpen !== row.id) || 
                                                    isOpen === row.id ? 
                                                    classes.visible 
                                                    : 
                                                    classes.hidden
                                                }
                                                color="primary"
                                                onClick={()=>handleExpand(row.id)}
                                            >
                                                See {isOpen === row.id ? "less" : "more"}
                                                {isOpen === row.id ?
                                                        <ExpandLessRoundedIcon 
                                                            className={classes.expandIcon}
                                                            classes={{root: classes.iconRoot}}
                                                        />
                                                        :
                                                        <ExpandMoreRoundedIcon 
                                                            className={classes.expandIcon}
                                                            classes={{root: classes.iconRoot}}
                                                        />
                                                    }
                                            </Typography>
                                            </>
                                            }
                                        </TableCell>                 
                                        <TableCell className={classes.cells}>
                                            {row.Target?.name}
                                        </TableCell>
                                        <TableCell className={classes.cells}>
                                            <div className={classes.column}>
                                                <Typography variant="body2">
                                                    {parseDate(row.createdAt)}
                                                </Typography>
                                                <Typography 
                                                    variant="body2" 
                                                    className={classes.time}>
                                                        {parseTime(row.createdAt)}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={classes.cells}>
                                            {getType(row.type)}
                                        </TableCell>
                                        <TableCell align="center" className={classes.cells} >
                                            <Button onClick={(e)=>handleToggle(e, row.id)}>
                                                <img src={actionsIcon} alt="actions icon" />
                                            </Button>
                                            <ActionsMenu 
                                                id={rowId}
                                                type="notebooks"
                                                isMenuOpen={isMenuOpen} 
                                                anchorEl={anchorEl} 
                                                handleToggle={(e)=>handleToggle(e)}
                                                setRename={setRename}
                                                archive={archiveNotebook}
                                            />
                                        </TableCell>
                                </TableRow>
                                <TableRow
                                    classes={{root: clsx({
                                        [classes.rowExpanded]: isOpen === row.id, 
                                    })}}
                                >
                                    <TableCell className={classes.cellCollapsed} colSpan={6}>
                                        <Collapse 
                                            in={isOpen === row.id} 
                                            className={classes.column}
                                        >
                                            <div className={classes.row}>
                                               <Typography 
                                                    variant="body1" 
                                                    className={classes.detailsLabel}
                                                >
                                                        Description: 
                                               </Typography>
                                               <Typography 
                                                    variant="caption" 
                                                    className={classes.details}
                                                >
                                                    {row.data.description}
                                                </Typography>
                                            </div>
                                            <div className={classes.row}>
                                               <Typography 
                                                    variant="body1" 
                                                    className={classes.detailsLabel}
                                                >
                                                    Resource: 
                                               </Typography>
                                               <Button
                                                    className={clsx(classes.details, classes.chip)}
                                                    endIcon={<img src={dataIcon} alt="data icon"/>}
                                                    onClick={()=>history.push(`/notebooks/${row.id}`)}
                                                >
                                                    <Typography variant="caption">
                                                        <CodeId name="notebook" id={row.id} />
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </Fragment>
                        )
                    })}
                </TableBody>
                <TableFooter>
                <TableRow>
                <TablePagination 
                    classes={{toolbar: classes.pagination}}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    count={(rows && rows.length) || 0} 
                    colSpan={6}
                    rowsPerPageOptions={[10, 25, 50]}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        </div>
        
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        notebooks: state.updates.notebooks,
        updatedNotebook: state.updates.updatedNotebook,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNotebooks: (data) => {dispatch(getNotebooks(data))},
        updateNotebook: (data) => {dispatch(updateNotebook(data))},
        archiveNotebook: (data) => {dispatch(archiveNotebook(data))},
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(NotebookList)